<template>
    <div id="contractAttachmentContainer">
        <div>
            <el-upload
                    drag
                    :action="uploadUrl"
                    :show-file-list="false"
                    :before-upload="beforeUploadFunc"
                    :on-success="uploadAttachmentSuccess"
                    :on-error="uploadErrorFunc"
                    style="width:100%"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
        </div>
<!--        <div>-->
<!--            <el-table :data="attachments" style="width: 100%" size="small">-->
<!--                <el-table-column prop="name" label="附件名" > </el-table-column>-->
<!--                <el-table-column prop="createTime" label="日期" >-->
<!--                    <template #default="scope">-->
<!--                        {{dateFormat(scope.row.createTime)}}-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column fixed="right" label="操作" width="100">-->
<!--                    <template #default="scope">-->
<!--                        <el-button @click="deleteAttachmentFunc(scope.row)" type="text" size="small">删除</el-button>-->
<!--                        <el-button type="text" size="small" @click="downloadAttachmentFunc(scope.row)" >下载</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--            </el-table>-->
<!--            <el-pagination-->
<!--                    small-->
<!--                    @current-change="handleCurrentChange"-->
<!--                    :current-page="currentPage"-->
<!--                    :page-size="pageData.size"-->
<!--                    layout="total, prev, pager, next"-->
<!--                    :total="pageData.totalElements"-->
<!--            >-->
<!--            </el-pagination>-->
<!--        </div>-->
    </div>
</template>

<script>
    import config from "../../config";
    import {ElLoading} from "element-plus";
    import {deleteContractAttachment, getContractAttachments} from "../../api/api";
    import {dateFormat} from "../common/commonUtil";
    import {globalTagClick} from "../../assets/js/tag/tag";
    export default {
        name: "ContractAttachment",
        props: ['contractId','uploadSuccess','parentName'],
        emits:[],
        data: function () {
            return {
                uploadUrl: config.baseUrl + `contract/${this.contractId}/attachment`,
                attachments: [],
                currentPage: 1,
                loadingInstance: null,
                pageData: {
                    number: 0,
                    size: 5,
                    totalElements: 0,
                    totalPages: 1
                },
                dateFormat: dateFormat,
            };
        },
        watch: {
        },
        computed: {
        },
        mounted:function () {
            //this.refreshData(this.pageData.number, this.pageData.size);
        },
        methods: {
            refreshData: function (currentPage = 0, pageSize = 10) {
                getContractAttachments(this.contractId, currentPage, pageSize).then(res => {
                    if (res.data.code === 0) {
                        this.pageData = res.data.data
                        this.attachments = res.data.data.content;
                    }
                }).catch(error => {
                    console.error(error);
                    this.$message.error('加载数据失败');
                });
            },
            reload:function(){
                this.refreshData(this.pageData.number, this.pageData.size);
            },
            /**
             * 页数改变监听
             */
            handleCurrentChange: function (currentPage) {
                this.currentPage = currentPage;
                this.refreshData(currentPage - 1, this.pageData.size)
            },
            beforeUploadFunc:function(){
                this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
                this.loadingInstance.setText('上传中')
            },
            uploadAttachmentSuccess:function (res,file,fileList) {
                if (res.code === 0) {
                  globalTagClick(`${this.parentName}-upload-attachment`);
                  this.$message.success('上传成功');
                    this.uploadSuccess();
                    this.reload();
                }else{
                    this.$message.error(res.msg);
                    // this.loadingInstance.setText(res.msg)
                }
                this.$emit('externalUploadSuccessHandler');
                this.loadingInstance.close();
            },
            uploadErrorFunc: function (err, file, fileList){
                this.$message.error('上传失败');
                this.loadingInstance.close();
            },
            deleteAttachmentFunc:function(attachment){
                deleteContractAttachment(this.contractId, attachment.id).then(res => {
                    if (res.data.code === 0) {
                        this.reload();
                    } else {
                        this.$message.error('删除失败');
                    }
                }).catch(error => {
                    this.$message.error('删除失败');
                });
            },
            downloadAttachmentFunc:function(attachment){
                location.href = this.getDownloadUrl(attachment.id);
            },
            getDownloadUrl:function (attachmentId) {
                return config.baseUrl + `contract/${this.contractId}/attachment/${attachmentId}`;
            }
        }
    }
</script>

<style   lang="scss">
    #contractAttachmentContainer {
      .el-upload-dragger{
        width:380px;
        height: 150px;
        margin:0 auto;
      }

       .el-upload--text{
        width: 100%;
      }

    }
</style>
