<template>
  <el-dialog title="确认取消" v-model="cancelContractDialogVisible" append-to-body>
    <span>取消后，此合同将终止在系统内的一切流程</span>
    <el-input type="textarea" v-model="cancelReason" placeholder="请输入取消理由"></el-input>
    <template #footer>
      <el-button @click="cancelContractDialogVisible = false;">取消</el-button>
      <el-button type="danger" data-tag-id="approval-contract-reject" @click="cancelContractDialogVisible = false;cancelContract();"
      >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import {globalTagClick} from "../../assets/js/tag/tag";
import {BehaviorPermission, checkUserBehaviorPermission} from "../common/permissionUtil";
import {cancelContract} from "../../api/api";
import {ElNotification} from "element-plus";

export default {
  name: "CancelContractDialog",
  props:{
    "teamId":{
      required:true
    },
    "contractIds":{
      required: true
    }
  },
  data(){
    return{
      cancelReason:'',
      cancelContractDialogVisible:false
    }
  },
  methods:{
    cancelContract() {
      globalTagClick('contract-view-cancel-contract');
      if (!checkUserBehaviorPermission(this.teamId,BehaviorPermission.CANCEL_CONTRACT))  return;


      cancelContract(this.contractIds,this.cancelReason)
          .then((res) => {
            if (res.status == 200) {
              if (res.data.code == 0) {
                if(this.$route.name!=='files') {
                  this.$router.push("/contract/files");
                }else{
                  ElNotification({
                    title: '执行结果',
                    message: `成功:${res.data.data.success.length}个,失败:${res.data.data.failed.length}`
                  })
                  this.$emit("onContractCanceled")
                }
              } else {
                this.metaMessage.error(res.data.msg);
              }
            } else {
              this.metaMessage.error("网络错误");
            }
          })
          .catch((e) => {
            console.error(e);
            this.metaMessage.error("网络错误");
          });
    },

    show(){
      this.cancelContractDialogVisible=true;
    }
  }
}
</script>

<style scoped>

</style>
