<template>
  <li
      class="contract-menu-item export-list-item"
  >
    <span>导出</span>
    <ul v-show="exportMenuVisible" class="dropdown-menu">
      <li
          :class="{ unselectable: contract.version?.fileType == 1 }"
          class="dropdown-menu-item"

      >
        <i><svg-icon name="word" width="14" height="14"/></i>
        <span>导出为Word</span>
        <i class="el-icon-caret-right"></i>
        <ul class="dropdown-menu-item-menu" v-show="contract.version?.fileType ===ContractFileType.PRO">
<!--          20220810 mys临时修改-->
          <li class="dropdown-menu-item-menu-item" data-tag-id="export-as-word-normal" @click="downloadAsWord"><i class="el-icon-document"></i><span>导出为修订模式</span></li>
<!--          <li class="dropdown-menu-item-menu-item" data-tag-id="export-as-word-revision" @click="$emit('exportRevision')"><i class="el-icon-document-checked"></i><span>导出为修订模式</span></li>-->
        </ul>
      </li>
      <li class="dropdown-menu-item" @click="downloadAsPdf" data-tag-id="contract-view-export-as-pdf">
        <i><svg-icon name="pdf" width="14" height="14"/></i>
        <span>导出为PDF</span>
      </li>
    </ul>
  </li>
</template>

<script>
import {ContractFileType} from "../../components/common/contractConstant";
import {globalTagClick} from "../../assets/js/tag/tag";
import {asyncDownloadFile} from "../../util";
import config from "../../config";
export default {
  name: "ExportListItem",
  props:{
    exportMenuVisible:{
      required:true,
    },
    contract:{
      required:true
    }
  },
  data(){
    return {
      ContractFileType,
    }
  },
  methods:{
    downloadAsWord() {
      globalTagClick('contract-view-download-word');
      asyncDownloadFile(this.getExportUrl("docx"));
    },
    downloadAsPdf() {
      globalTagClick('contract-view-download-pdf');
      asyncDownloadFile(this.getExportUrl("pdf"));
    },
    getExportUrl: function (type) {
      let version = this.contract.version.fileType===ContractFileType.PRO?this.contract.version:this.contract.version.smallVersions[0]
      return config.baseUrl + `/contract/${this.contract.id}/contract-version/${version.id}/export?type=${type}`;
    },
  }
}
</script>

<style scoped>

</style>
