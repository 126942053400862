<template>
  <div style="display: inline-block;">
    <contract-popover v-if="contract.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN && isApprover" style="margin-right: 20px">
      <template #reference>
        <el-button cancelcustom type="primary" style="color:#fff !important;border-radius: 0;" size="small">通过/驳回 <i class="el-icon-arrow-down"></i></el-button>
      </template>
      <div>
        <div data-tag-id="approval-contract-agreement" class=" draft-operation-item draft-operation-pass"  @click="onClickApproveContract(ApprovalStatus.PASSED)">通过审批</div>
        <div data-tag-id="approval-contract-decline" class=" draft-operation-item draft-operation-decline"  @click="rejectDialogVisible=true">驳回审批</div>
      </div>

    </contract-popover>

    <el-button data-tag-id="approval-launch-ready-to-sign" cancelcustom size="small" style="background-color: rgb(226,140,38);border-color: rgb(226,140,38);color: white;border-radius: 0;margin-right: 20px" v-if="(contract.approvalStatus==ContractApprovalStatus.DRAFT )  && (checkUserBehaviorPermission(userInfo.mainSiteTeamId,BehaviorPermission.EDIT_CONTRACT_CONTENT)) " @click="onClickLaunchReadyToSign">发起审批</el-button>


    <div v-if="contract.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED">
      <el-button data-tag-id="approval-launch-sign" cancelcustom size="small" style="background-color: rgb(35,182,92);border-color: rgb(35,182,92);color: white;border-radius: 0;margin-right: 20px" v-if=" (checkUserBehaviorPermission(userInfo.mainSiteTeamId,BehaviorPermission.UPDATE_CONTRACT_META_INFO)||checkUserBehaviorPermission(userInfo.mainSiteTeamId,BehaviorPermission.UPLOAD_SIGNED_CONTRACT))" @click="onClickLaunchSign(true)">上传签署版本</el-button>

    </div>



<!--    <el-button  @click="setContractApprovalProcessAndPermission()" plain size="mini"-->
<!--               v-if="approvalProcess==null && userInfo && userInfo.isManagerRole" >设置该上传合同的权限与流程-->
<!--    </el-button>-->
<!--    <el-button  @click="setContractApprovalProcessAndPermission()" plain size="mini"-->
<!--               v-if="(approvalProcess!=null && userInfo && userInfo.isManagerRole)" >修改权限和审批流程-->
<!--    </el-button>-->

    <el-dialog :title="signTitle" v-model="centerDialogVisible" width="40%" center append-to-body @close="beforeClose" >
      <div v-if="teamInfo.finalizedNeedNotCommitNewVersion">
        <div class="signed-upload">
          <el-radio-group style="width: 100%" v-model="signMethod">
            <el-radio :label="0" ><span style="color: black;">上传签署版本</span></el-radio>
            <div style="text-align: center;width: 100%;padding: 10px 0">
              <el-upload
                  ref="upload"
                  v-loading="uploadLoading"
                  :action="uploadUrl"
                  :before-upload="beforeUpload"
                  :on-success="onSuccess"
                  :show-file-list="false"
                  :on-change="onFileChange"
                  :auto-upload="false"
                  class="upload-demo"
                  drag


              >
                <div v-if="uploadFile" class="file-info">
                  <div class="content">
                    <div>
                      <el-image :src="uploadFile.name.endsWith('.pdf')?pdf:word"></el-image>
                    </div>
                    <div>
                      <span class="el-upload__text">{{uploadFile.name}}</span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip">支持上传 docx/pdf 文件</div>
                </div>


              </el-upload>
            </div>

            <el-radio :label="1"><span style="color: black;">将<span style="color: #5280FF">定稿版</span>设为已签署版</span></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div v-else>
        <div style="text-align: center;width: 100%;padding: 10px 0">
          <el-upload
              ref="upload"
              v-loading="uploadLoading"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              :data="{ targetStatus: 21 }"
              :on-success="onSuccess"
              :show-file-list="false"
              :on-change="onFileChange"
              :auto-upload="false"
              class="upload-demo"
              drag


          >
            <div v-if="uploadFile" class="file-info">
              <div class="content">
                <div>
                  <el-image :src="uploadFile.name.endsWith('.pdf')?pdf:word"></el-image>
                </div>
                <div>
                  <span class="el-upload__text">{{uploadFile.name}}</span>
                </div>
              </div>
            </div>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip">支持上传 docx/pdf 文件</div>
            </div>


          </el-upload>
        </div>
      </div>

      <template #footer>
    <span class="dialog-footer">

      <el-button type="primary"  style="width: 100px" data-tag-id="approval-upload-new-contract-as-signed" @click="upload"
      >确定</el-button
      >
    </span>
      </template>
    </el-dialog>
    <el-dialog title="确认驳回" v-model="rejectDialogVisible" append-to-body>
      <span>请输入驳回理由</span>
      <el-input type="textarea" v-model="rejectReason"></el-input>
      <template #footer>
        <el-button @click="rejectDialogVisible = false;">取消</el-button>
        <el-button type="danger" data-tag-id="approval-contract-reject" @click="rejectDialogVisible = false;onClickApproveContract(ApprovalStatus.REJECT);"
        >驳回</el-button
        >
      </template>
    </el-dialog>
  </div>


</template>

<script>
import {

  launchApprovalProcess,
  approveContract,
  getContractApprovalProcess, finalizedContract, draftFinalized
} from "../api/api";
import {ElMessage} from "element-plus";
import {ContractApprovalStatus,ApprovalType,ApprovalStatus} from "../constant/contract";
import ContractPopover from "../components/ContractPopover";
import {ContractSharePermissionType} from "../constant/contract";
import {checkUserBehaviorPermission,BehaviorPermission,existBehaviorPermission} from "./common/permissionUtil";
import pdf from "../assets/images/pdf.png"
import word from "../assets/images/word.png"
import config from "../config";
export default {
  name: "ApprovalProcessControl",
  components:{ContractPopover},
  props:{
    contractId:{
      type:String,
      default:''
    },
    onLaunchApprovalProcess:{
      type:Function,
      default:null
    },
    onHandleApprovalProcess:{
      type:Function,
      default:null
    },
    contract:{
      type:Object,
      default:null
    },
    isApprover:{
      type:Boolean,
      default:false
    }
  },

  data(){
    return {
      ContractApprovalStatus,
      ApprovalType,
      ApprovalStatus,
      approvalProcess:null,
      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      teamInfo:JSON.parse(sessionStorage.getItem("teamInfo")),
      centerDialogVisible:false,
      rejectDialogVisible:false,
      rejectReason:'',
      ContractSharePermissionType,
      checkUserBehaviorPermission,
      BehaviorPermission,
      existBehaviorPermission,
      signMethod:0,
      signTitle:"将已签署的合同上传留档",
      uploadFile: null,
      pdf,
      word,
      uploadLoading:false
    }
  },
  watch:{
    contract:function (){
      this.init();
    },
  },
  computed:{
    uploadUrl(){
      return `${config.baseUrl}contract/${this.contract.id}/contract-version/upload-as-signed`
    }
  },
  mounted() {
    this.init();
  },
  methods:{

    init(){
      if(this.teamInfo&&!this.teamInfo.finalizedNeedNotCommitNewVersion){
        this.signTitle = "上传签署版"
      }
    },
    onFileChange(file){
      this.uploadFile=file
    },
    beforeClose(){
      this.uploadFile=null
      this.$refs.upload.clearFiles()
    },
    onClickLaunchReadyToSign(){
      this.launchApprovalProcess(this.contractId,ApprovalType.READY_TO_SIGN)


    },
    onClickApproveContract(status){
      approveContract(this.contractId, {status:status,message:this.rejectReason}).then(res=>{
        if(res.status==200){
          if(res.data.code==0){
            if(status==1) {
              ElMessage.success("已通过");
            }
            else{
              ElMessage.success("已驳回");
            }
            this.init();
            this.onHandleApprovalProcess();
          }else {
            ElMessage.error(res.data.msg)
          }
        }
      }).catch(e=>{
        ElMessage.error("网络错误")
        console.log(e)
      })
    },
    onClickLaunchSign(needUpload){
      this.centerDialogVisible=true;

    },
    onSuccess: function (response, file, fileList) {
      this.uploadLoading = false;
      this.centerDialogVisible = false;
      if (response.code == 0) {
        ElMessage.success("上传成功");
        this.init();
        this.onLaunchApprovalProcess()
      } else {
        ElMessage.error(response.msg);
      }
    },

    launchApprovalProcess:function (contractId,approvalType){
      launchApprovalProcess(contractId,{approvalType:approvalType}).then(res=>{
        if(res.status==200){
          if(res.data.code==0){
            ElMessage.success("操作成功")
            this.onHandleApprovalProcess();

          }else {
            ElMessage.error(res.data.msg)
          }
        }
      }).catch(e=>{
        ElMessage.error("网络错误")
        console.log(e)
      })
    },
    setContractApprovalProcessAndPermission: function () {
      this.$router.push({
        name: 'contractProcessPermission',
        query: {
          contractId: this.contractId
        }
      })
    },
    cancelUpload(){
      this.centerDialogVisible = false;
      this.onClickLaunchSign(false)
    },
    beforeUpload(){
      this.uploadLoading=true;
    },
    upload(){

      if(this.signMethod===0){
        this.$refs.upload.submit()
      }else{
        if(checkUserBehaviorPermission(this.userInfo.mainSiteTeamId,BehaviorPermission.LAUNCH_COOPERATION)){
          finalizedContract(this.contractId).then(res=>{
            if(res.status==200){
              if(res.data.code==0) {
                this.uploadLoading=false;
                this.centerDialogVisible = false;
                ElMessage.success("操作成功");

                this.onHandleApprovalProcess();
              }else{
                ElMessage.error(res.data.msg)
              }
            }

          })

        }
      }
    }

  }
}
</script>

<style scoped lang="scss">
  .draft-operation-item{
    text-align: left;
    font-weight: bold;
    cursor: pointer;
    height: 20px;
    margin: 10px;
    font-size: .875rem;
    white-space: nowrap;
  }
  .draft-operation-pass{
    color:var(--lightBlue);
    &:hover{
      color:var(--darkBlue);
    }
  }
  .draft-operation-decline{
    color:rgb(223,60,47);
    &:hover{
      color: rgb(167, 45, 35);
    }
  }
  .el-button{
    font-weight: bold;
  }


</style>

<style scoped>
.upload-demo >>> .el-upload{
  width: calc(100% - 40px);
}
.upload-demo >>> .el-upload .el-upload-dragger{
  width: 100%
}

.file-info{
  height: 100%;
}
.file-info::before{
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.file-info .content{
  display: inline-block;
  vertical-align: middle;
}

.dialog-footer{
  display: block;
  text-align: right;
}

.signed-upload >>> .el-radio__inner{
  border-color: black;
}

.signed-upload >>> .el-radio__input.is-checked .el-radio__inner{
  border-color: #5280FF;
}

.el-icon-upload{
  color: #5280FF;
  fill:currentColor;
}

.upload-demo >>> .el-upload-dragger{
  border:2px dashed #5280FF;

}

.upload-demo >>> .el-upload{

}
</style>
