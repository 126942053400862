<template>
  <el-dialog class="contract-word-export-setting" title="导出设置" v-model="showDialog" width="500px">
    <div style="text-align: left">
      <b>请选择需要导出的修订版本</b>
      <div style="margin-top:10px">
        <MetaLevelSelector
            v-if="versionsPanelData"
            :value-prop-name="'id'"
            :selector-data="versionsPanelData"
            :child-prop-name="'smallVersions'"
            :classify-prop-name="'group'"
            placeholder="请选择修订版"
            v-model:value="exportRevisionVersionId"
            :z-index="3000"
            @selected="resolveLevelSelected('left',$event)"

        >
          <template #leftPart="{data}">
            <div class="versionSelector-tip"></div>
          </template>
          <template #child="{child,leaf,index}">
            <MetaSelectorOption
                v-if="child.group != '补充协议' && leaf.parent.group != '补充协议'"
                :label="`v${leaf.level.length==1 ? (child.bigVersion) : (child.bigVersion + '.' + child.smallVersion)} ${child.createTime.slice(0,-3)}`"
                :value="child.id"
            >
              {{
                `v${leaf.level.length==1 ? (child.bigVersion) : (child.bigVersion + '.' + child.smallVersion)} ${child.createTime.slice(0,-3)}`
              }}
            </MetaSelectorOption>
            <MetaSelectorOption
                v-else-if="child.group == '补充协议'"
                :label="`${child.title[0] + child.title.slice(-1)} ${child.createTime.slice(0,-3)}`"
                :value="child.id"
            >
              {{ `${child.title[0] + child.title.slice(-1)} ${child.createTime.slice(0,-3)}`}}
            </MetaSelectorOption>
            <MetaSelectorOption
                v-else
                :label="`${leaf.parent.title[0] + leaf.parent.title.slice(-1)}v${leaf.level.length==1 ? (child.bigVersion) : (child.bigVersion + '.' + child.smallVersion)} ${child.createTime.slice(0,-3)}`"
                :value="child.id"
            >
              {{ `${leaf.parent.title[0] + leaf.parent.title.slice(-1)}v${leaf.level.length==1 ? (child.bigVersion) : (child.bigVersion + '.' + child.smallVersion)} ${child.createTime.slice(0,-3)}`}}
            </MetaSelectorOption>
          </template>
        </MetaLevelSelector>
      </div>

    </div>
    <template #footer>
      <el-button type="default" @click="showDialog = false">取消</el-button>
      <el-button type="primary" @click="exportWordWithRevision">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import MetaLevelSelector from "../selector/MetaLevelSelector";
import MetaSelectorOption from "../selector/MetaSelectorOption";
import {asyncDownloadFile} from "../../util";
export default {
  name: "ContractWordExportSetting",
  components: {MetaSelectorOption, MetaLevelSelector},
  props:{

    "versionsPanelData":{
      required: true
    },
    "contract":{
      required:true
    }
  },
  watch:{
    "versionsPanelData":{
      handler:function (){
        if(this.versionsPanelData && this.versionsPanelData[0]) {
          let smallVersions = this.versionsPanelData[0].smallVersions;
          if (smallVersions && smallVersions.length > 0) {
            this.exportRevisionVersionId = smallVersions[smallVersions.length - 1].id
          } else {
            this.exportRevisionVersionId = this.versionsPanelData[0].id;
          }
        }
      },
      deep:true
    }
  },

  data(){
    return{
      showDialog:false,
      exportRevisionVersionId:null
    }
  },
  mounted() {

  },
  methods:{
    exportWordWithRevision(){
      let url = `/contract/${this.contract.id}/contract-version/${this.contract.version.id}/export-revision`
      let param={targetVersionId:this.exportRevisionVersionId}
      asyncDownloadFile(url,param)
      this.close()

    },
    show(){
      this.showDialog=true;
    },
    close(){
      this.showDialog=false;
    },
    resolveLevelSelected(side,{target,selector}){

      selector.closeDropDown()
    },
  }
}
</script>

<style scoped>

</style>
