<template>
  <li class="contract-inform-list-item dropdown-menu-item"
      :class="{disabled}"
       data-tag-id="contract-view-inform-setting"
       @click="openWindow">
    <i style="color:#999999"><svg-icon name="inform" width="14" height="14"/></i><span>公告设置</span>
    <el-dialog
        v-model="windowVisible"
        custom-class="contract-inform-window"
        :append-to-body="true"
        title="公告设置"
        :width="500"
    >
      <el-input type="textarea" v-model="copyInformData.message" placeholder="请在此处输入内容,对该合同有读权限的成员均可见此公告"/>
      <template #footer>
        <div class="ciwli-operations">
          <el-button @click="closeWindow" type="default">取消</el-button>
          <el-button @click="handleConfirm" :disabled="!isModified" type="primary">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </li>
</template>

<script>
import {deepClone} from "../../util";

export default {
  name: "ContractInformListItem",
  props:{
    contractInform:{
      required:false,
      default:{
        message:''
      }
    },
    disabled:{
      required:false,
      default:false
    }
  },
  data(){
    return {
      windowVisible:false,
      //这个变量是用来保持外部数据的拷贝
      copyInformData:{
        message:''
      },
    }
  },
  watch:{
    //每次打开窗口拷贝一次
    windowVisible:{
      handler:function (newVal){
        if(newVal){
          this.copyInformData = deepClone(this.$props.contractInform);
        }
      }
    }
  },
  computed:{
    isModified(){
      return this.copyInformData.message !== this.$props.contractInform.message;
    }
  },
  methods:{
    toggleWindow(assignedVisible){
      if(assignedVisible !== undefined){
        this.windowVisible = assignedVisible;
      }
      this.windowVisible = !this.windowVisible;
    },
    openWindow(){
      this.windowVisible = true;
    },
    closeWindow(){
      this.windowVisible = false;
    },
    handleConfirm(){
      this.$emit('confirmed',this.copyInformData);
      this.windowVisible = false;
    }
  }
}
</script>
<style>
.contract-inform-window .el-dialog__header{
  text-align: center;
}

</style>
<style scoped>
.contract-inform-list-item.disabled{
  pointer-events: none;
}
</style>
