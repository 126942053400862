import {ElMessageBox} from "element-plus";

const confirmBeforeInvoke = (msg,title,type,handle)=>{
    ElMessageBox.confirm(msg,title,{
        showCancelButton:true,
        type:type,
    }).then(handle)
}

export {
    confirmBeforeInvoke
}
