<template>
<el-container style="text-align: left;margin: 20px">
  <el-header style="height:80px;">

    <div style="display: flex; flex-wrap: wrap-reverse;">
        <el-select v-model="originVersionId" placeholder="请选择" style="margin-right: 20px">
          <el-option
              v-for="item in versionList"
              :key="item.id"
              :label="item.fileName"
              :value="item.id">
          </el-option>
        </el-select>


        <el-select v-model="diffVersionId" placeholder="请选择" style="margin-right: 20px">
          <el-option
              v-for="item in versionList"
              :key="item.id"
              :label="item.fileName"
              :value="item.id">
          </el-option>
        </el-select>

      <el-col :span="2">
        <el-button type="primary" @click="diffVersion">对比</el-button>
      </el-col>

    </div>


  </el-header>
  <el-main>
    <el-table
        :data="versionList"
        border
        >
      <el-table-column
          fixed
          prop="fileName"
          label="名称"
          min-width="30%"
          show-overflow-tooltip
        >
      </el-table-column>
      <el-table-column
          prop="fileType"
          label="类别"
          min-width="10%">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
          width="180">
        <template #default="scope">

          {{new Date(scope.row.createTime).toLocaleString()}}
        </template>
      </el-table-column>

      <el-table-column
          prop="updateTime"
          label="更新时间"
          width="180">
        <template #default="scope">

          {{new Date(scope.row.updateTime).toLocaleString()}}
        </template>
      </el-table-column>

      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template #default="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
          <el-button v-if="scope.$index!=0" @click="rollBack(scope.row)" type="text" size="small">回退</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</el-container>
</template>

<script>
import {getContractVersions, revertToOldVersion} from "../api/api";
import {ElMessage} from "element-plus";
export default {
  name: "ContractVersion",
  props:['contractId','onRevert'],
  mounted() {
    getContractVersions(this.contractId).then(res=>{
      if(res.status==200){

        this.versionList=res.data;
      }
    }).catch(e=>{
      console.error(e);
      ElMessage.error("请求失败");
    })
  },
  data(){
    return{
      versionList:[],
      originVersionId:null,
      diffVersionId:null,
    }
  },
  methods:{
    handleClick(data){
      let newpage = this.$router.resolve({
        name:'contractHistory',
        query:{
          fileId:data.fileId,
          fileType:data.fileType,
          contractId:this.contractId
        }
      })

      window.open(newpage.href)

    },
    diffVersion(){
      let newpage = this.$router.resolve({
        name: "contractDiff",
        query: {
          oId: this.originVersionId,
          dId:this.diffVersionId
        },
        params:{
          contractId:this.contractId
        }
      });
      window.open(newpage.href)
    },
    rollBack(data){
      revertToOldVersion(this.contractId,data.id).then(res=>{
        if(res.status==200){
          if(res.data.code==0){
            ElMessage.success("操作成功");

            this.onRevert();

          }
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
